import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import moment from 'moment'
import useTableRenderItems from '../useTableRenderItems'
import {
  convertToFractionalNumber,
  crossedOutText,
  getColumnSorter,
} from '../../utils/helpers'
import {TagsComponent} from '../../components/antd-components/tags'
import {formattedDate} from '../../constants'
import {useDateRangeTableSettings} from '../../hooks'
import useInventoryService from './inventoryService'
import DetailsActionButton from '../../components/antd-components/tableAction-buttons/details-button/DetailsActionButton'

const useInventoryTable = ({tableSettings, getSelectedInventory}) => {
  const {t} = useTranslation()

  const {renderNameById} = useTableRenderItems()

  const {setInventoryTableSettings} = useInventoryService()

  const stores = useSelector((state) => state.stores.stores)
  const users = useSelector((state) => state.users.users)

  const submissionDateFilterProps = useDateRangeTableSettings({
    dateFromFilterName: 'f_submissionDateFrom',
    dateToFilterName: 'f_submissionDateTo',
    tableSettings,
    setTableSettings: setInventoryTableSettings,
  })

  const confirmationDateFilterProps = useDateRangeTableSettings({
    dateFromFilterName: 'f_confirmationDateFrom',
    dateToFilterName: 'f_confirmationDateTo',
    tableSettings,
    setTableSettings: setInventoryTableSettings,
  })

  const getColumns = () => [
    {
      title: t('Store'),
      dataIndex: 'storeId',
      key: 'f_storeId',
      align: 'center',
      width: '15%',
      sorter: true,
      filteredValue: tableSettings.f_storeId,
      sortOrder: getColumnSorter(tableSettings, 'storeId'),
      filters: stores.map((item) => ({
        key: item.id,
        value: item.id,
        text: item.name,
      })),
      render: (_, record) => {
        if (record.store.deletedDate) {
          return (
            <div style={{textAlign: 'left'}}>
              {crossedOutText(record.store.name)}
            </div>
          )
        }
        return <div style={{textAlign: 'left'}}>{record.store.name}</div>
      },
    },
    {
      title: t('Status'),
      dataIndex: 'status',
      key: 'f_status',
      align: 'center',
      width: '15%',
      filteredValue: tableSettings.f_status,
      sortOrder: getColumnSorter(tableSettings, 'status'),
      sorter: true,
      filters: [
        {
          text: t('Sent'),
          value: 'submitted',
        },
        {
          text: t('ConfirmedStatus'),
          value: 'confirmed',
        },
      ],
      render: (_, record) => {
        if (record.confirmedById === null) {
          return (
            <TagsComponent
              color="orange"
              keys={record.id}
              tagText={t('Sent')}
            />
          )
        }
        return (
          <TagsComponent
            color="green"
            keys={record.id}
            tagText={t('ConfirmedStatus')}
          />
        )
      },
    },
    {
      title: t('Author'),
      dataIndex: 'submittedById',
      key: 'f_submittedById',
      align: 'center',
      width: '15%',
      filteredValue: tableSettings.f_submittedById,
      sortOrder: getColumnSorter(tableSettings, 'submittedById'),
      filters: users.map((item) => ({
        key: item.id,
        value: item.id,
        text: item.name,
      })),
      sorter: true,
      render: (value) => (
        <div style={{textAlign: 'left'}}>
          {renderNameById({id: value}, users)}
        </div>
      ),
    },

    {
      title: t('Submission date'),
      dataIndex: 'submissionDate',
      width: '20%',
      align: 'center',
      sorter: true,
      sortOrder: getColumnSorter(tableSettings, 'submissionDate'),
      ...submissionDateFilterProps,
      filteredValue: '',
      render: (value) => moment(value).format(formattedDate.DateWithTime),
    },
    {
      title: t('Confirmed'),
      dataIndex: 'confirmedById',
      width: '15%',
      align: 'center',
      sorter: true,
      filteredValue: '',
      sortOrder: getColumnSorter(tableSettings, 'confirmedById'),
      render: (value, record) => (
        <div style={{textAlign: 'left'}}>{record?.confirmedBy?.name}</div>
      ),
    },
    {
      title: t('Confirmation date'),
      dataIndex: 'confirmationDate',
      width: '20%',
      align: 'center',
      sorter: true,
      ...confirmationDateFilterProps,
      filteredValue: '',
      sortOrder: getColumnSorter(tableSettings, 'confirmationDate'),
      render: (value) =>
        value !== null ? moment(value).format(formattedDate.DateWithTime) : '-',
    },
    {
      title: t('Result'),
      dataIndex: 'inventoryMoneyResult',
      key: 'inventoryMoneyResult',
      align: 'center',
      width: '20%',
      render: (value) => convertToFractionalNumber(value),
    },
    {
      title: t('Actions'),
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      width: '10%',
      render: (_, record) => (
        <div>
          <DetailsActionButton
            onClick={() => {
              getSelectedInventory(record.id)
            }}
          />
        </div>
      ),
    },
  ]

  return {inventoryColumns: getColumns()}
}

export default useInventoryTable
